.leaflet-container {
    height: 1000px;
}


  .panel {
    border-radius: 5px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
  }
  .panel__map {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    background-color: #fff;
  }
  .btn-back-map{
    position: absolute;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: right;
    top: 20px;
    left: 100px;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    font-size: 20px;
    box-shadow: 0 0 5px 0 rgba(255,255,255, 0.3);
    background: rgba(0,0,0,0.8);
    cursor: pointer;
  }
  .btn-back-map:hover{
    background-color: #555;
  }
  .legend {
    position: absolute;
    z-index: 999;
    left: 20px;
    bottom: 20px;
    padding: 10px 5px;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(255,255,255, 0.3);
    font: 13px/1 sans-serif;
    background: rgba(0,0,0,0.8);
  }
  .legend > div {
    position: relative;
    display: flex;
    align-items: center;
    height: 20px;
    padding: 0 5px;
    color: #fff;
  }
  .legend > div::before {
    content: "";
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    background-color: var(--color);
  }
  .hover-info {
    position: absolute;
    z-index: 999;
    display: flex;
    align-items: center;
    top: 20px;
    right: 50px;
    height: 24px;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    font-size: 20px;
    box-shadow: 0 0 5px 0 rgba(255,255,255, 0.3);
    background: rgba(0,0,0,0.8);
  
  }
  .info {
    position: absolute;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: right;
    top: 20px;
    right: 50px;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    font: 20px;
    box-shadow: 0 0 5px 0 rgba(255,255,255, 0.3);
    background: rgba(0,0,0,0.8);
    text-align: left;
  }
  .info strong {
    font-size: 20px;
    display: block;
    padding-top: 5px;
  }
  .info span {
    text-align: left;
  }
  .uf_name{
    font-size: 24px;
    text-align: center;
  }
  .subtitle{
    font-weight: bold;
    margin-top: 10px;
    text-align: left;
  }