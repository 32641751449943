body,
html {
  height: 100% !important;
  background-color: var(--chakra-colors-blue-500);
}
select {
  color: #000 !important;
}
.tableborder {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}
.tableborder tr {
  border-top: 1px solid #000;
}
.tableborder td {
  margin: 0;
  padding: 3px 10px;
}
.tableborder tr:first-child {
  border-top: 0;
}
.tableborder td {
  /* border: 1px solid red; */
}
.border_left {
  /* border-left: 1px solid #000; */
}
.border_right {
  /* border-right: 1px solid #000; */
}
.tableborder_1 {
  width: 200px;
}
.tableborder_2 {
  width: 200px;
}
.tableborder_3 {
  width: 400px;
}

.tableborder_4 {
}
.tableborder_5 {
  width: 200px;
}
.tableborder_6 {
  width: 200px;
}
.tableborder_total {
  width: 150px;
  text-align: right;
}

.table-report {
  font-size: 14px;
}

.table-report,
.table-report table,
.table-report td {
  height: 100%;
}
.table-report th {
  height: 160px;
  font-size: 11px;
}

.table-report table {
  width: 100%;
}
.table-report table,
.table-report td,
.table-report th {
  margin: 0;
  padding: 0;
}

.table-report-td {
  padding: 10px !important;
}

.table-filter {
  width: 100%;
}
.table-filter td {
  font-size: 11px;
  border-top: 1px solid #333;
  padding: 5px 2px;
}

.table-matriz th,
.table-matriz td {
  min-width: 150px !important;
}

.subTable tr:first-child td {
  border: none;
  height: 100%;
}

.no-padding {
  padding: 0 !important;
}

.dashboard-sidebar {
  width: 350px;
  float: left;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.selected-with-lines {
  -webkit-padding-end: 2rem;
  padding-inline-end: 2rem;
  width: 95%;
  min-width: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  background: var(--chakra-colors-white);
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: var(--chakra-lineHeights-normal);
  font-weight: 400;
  font-size: var(--chakra-fontSizes-xs);
  -webkit-padding-start: var(--chakra-space-4);
  padding-inline-start: var(--chakra-space-4);
  border-radius: var(--chakra-radii-md);
  border: 1px solid;
  border-color: var(--chakra-colors-gray-200);
}

.matrizFilterItem {
  border: 1px solid #aaa;
  border-radius: 10px;
  padding: 10px 10px 0;
  margin-bottom: 15px;
}

.btn-red {
  background-color: #f00 !important;
}

.boxScroll {
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  border: 1px solid #777;
  padding: 10px;
}

.top_bar select {
  width: 400px;
}

.top_bar .chakra-form-control {
  margin-right: 30px;
}

.filtersTable {
  display: block;
  margin-bottom: 20px;
  width: 400px;
}

.width-auto {
  width: auto !important;
}

.width-auto th,
.width-auto td {
  padding: 10px 15px;
}

.pre {
  white-space: pre-wrap;
}

.form-checkbox > div {
  display: inline-block;
  width: auto;
  margin: 5px;
  padding: 5px 7px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #efefef;
  font-size: 12px;
}

.cell-background {
  background-color: #eee;
}

.chakra-table th,
.chakra-table td {
  color: #333 !important;
}

.table-filter th {
  font-size: 11px;
}

.bold {
  font-weight: bold;
}

.background-td {
  background-color: #cad2d7;
}

.text-right {
  text-align: right;
}

.td-value {
  height: 50px !important;
}

.logoFooter {
  height: 40px !important;
  width: fit-content !important;
}

.p {
  margin-bottom: 10px !important;
}

.txt-left {
  text-align: left !important;
}

.btn-black {
  display: inline-block;
  padding: 5px 8px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  box-shadow: 0 0 5px 0 rgba(255, 255, 255, 0.3);
  background: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  text-align: center;
}
.btn-g {
  font-size: 20px;
  padding: 5px 10px;
}

.btn-black:hover {
  background-color: #555;
}

.clear {
  clear: both;
}

.tableContainer {
  width: calc(100% - 200px);
  overflow-x: scroll;
}
